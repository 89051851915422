import { apiUrl } from "./base";
import axios from 'axios';

const getHeaderText = async() => {
    return await axios.get(`${apiUrl}/header`);
}

const getAboutText = async() => {
    return await axios.get(`${apiUrl}/about`);
}

const getFeatureText = async() => {
    return await axios.get(`${apiUrl}/features-texts`);
}

const getFeatureHead = async() => {
   return await axios.get(`${apiUrl}/features-head`);
}

const getStepsText = async() => {
    return await axios.get(`${apiUrl}/steps-texts`);
}

const getStepsHead = async() => {
   return await axios.get(`${apiUrl}/steps-head`);
}

const getReviewsText = async() => {
    return await axios.get(`${apiUrl}/reviews`);
}

const getReviewsHead = async() => {
    return await axios.get(`${apiUrl}/reviews-head`);
}

const getClientsImages = async() => {
    return await axios.get(`${apiUrl}/clients`);
}

const getClientsHead = async() => {
    return await axios.get(`${apiUrl}/clients-head`);
}

const createRequestForDemo = async(request) => {
    const requestOptions = {
        headers: {'content-type': 'application/json'}
    };

    return await axios.post(`${apiUrl}/demo-requests`, request, requestOptions);
}

const getLogo = async() => {
    return await axios.get(`${apiUrl}/logo`);
}

const getBackgroundImg = async() => {
    return await axios.get(`${apiUrl}/background`);
}

const getStatistics = async() => {
   return await axios.get(`${apiUrl}/statistics`);
}

const getFooterText = async() => {
    return await axios.get(`${apiUrl}/footer-text`);
}

const showStats = async() => {
    return await axios.get(`${apiUrl}/show-stats`);
}

const createSignupForm = async(request) => {
    const requestOptions = {
        headers: {'content-type': 'application/json'}
    };

    return await  axios.post(`${apiUrl}/sign-up-details`, request, requestOptions);
}

const showSignup = async() => {
    return await axios.get(`${apiUrl}/show-signup`);
}

const upload = async(request) => {
    const requestOptions = {
        headers: {'content-type': 'multipart/form-data'}
    };

    return await axios.post(`${apiUrl}/upload`, request, requestOptions);
}

const getPrivacyText = async () => {
    return await axios.get(`${apiUrl}/privacy-policy`);
}


export const userService = {
    getHeaderText,
    getAboutText,
    getFeatureText,
    getFeatureHead,
    getStepsText,
    getStepsHead,
    getReviewsText,
    getReviewsHead,
    getClientsImages,
    getClientsHead,
    createRequestForDemo,
    getLogo,
    getBackgroundImg,
    getStatistics,
    getFooterText,
    showStats,
    createSignupForm,
    showSignup,
    upload,
    getPrivacyText
};