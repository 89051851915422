import React, { useEffect, useState } from 'react';
import { userService } from '../services/http';
import MENV from "../env";
let api = MENV();

const PageAbout = (props) => {
    const [collection, setCollection] = useState();
    const [stats, setStats] = useState();

    useEffect(() => {
        (async () => {
            const aboutText = await getAboutTexts();
            const showStatsSection = await showStats();
            if(showStatsSection.data.show){
                const stats = await getStats();
                setStats(stats.data);
            }
            setCollection(aboutText.data);
        })();
    }, [])

    const getAboutTexts = async () => {
        const aboutText = await userService.getAboutText();
        return aboutText;
    }

    const getStats = async () => {
        const stats = await userService.getStatistics();
        return stats;
    }

    const showStats = async () => {
        const stats = await userService.showStats();
        return stats;
    }
    return (
        <section id="about" className="page-section page-section__about d-flex justify-content-center align-items-center flex-column">
            <div className="page-section__head wow" data-wow-delay=".5s">
                <img src="img/inverted-bowl.svg" alt="inverted bowl" />
                {collection && collection.sectionInfo && <h4 className="page-section__head--sub wow fadeInUp">{collection.sectionInfo}</h4>}
                {collection && collection.sectionHead && <h3 className="page-section__head--main wow fadeInUp font-weight-bold">{collection.sectionHead}</h3>}
            </div>
            <div className="row">
                <div className="col-lg-6 page-section__about--img">
                    {collection && collection.sectionImg && <img src={api.SERVICE_API + collection.sectionImg.url} alt="About Illustration" className="img-fluid wow fadeInLeft" />}
                </div>
                {collection && collection.sectionDescription && <div className="col-lg-6 page-section__about--text wow fadeInRight" data-wow-delay=".5s">
                    <p className="my-3" dangerouslySetInnerHTML={{ __html: collection.sectionDescription }}>
                    </p>
                    <div className="d-flex align-items-left">
                        <button className="btn btn-danger button button--red" data-toggle="modal" data-target="#demo">Request a demo</button>
                    </div>
                </div>}
            </div>
            <div className="row mt-3 justify-content-center align-items-center">
                {stats && stats.map((element, index) => {
                    return (<div key={index} className='col-lg-6  wow fadeInUp col-md-6 col-sm-6 d-flex justify-content-center align-items-center'>
                        <div className="about-stats my-3 p-3 d-flex flex-column justify-content-center">
                            <span>{element.name}</span>
                            <span className="my-2 stats">{element.stats}</span>
                        </div>
                    </div>)
                })}
            </div>

        </section>
    );
}

export default PageAbout;
