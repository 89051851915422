import React, { useState } from 'react';
import { userService } from '../services/http';
import { ToastsStore } from 'react-toasts';

const PageModal = (props) => {
    const [user, setUser] = useState({
        name: '',
        email: '',
        phone: '',
        intention: '',
    })

    const [errorObj, setErrorObj] = useState({
            name: "",
            email: "",
            phone: "",
            intention: ""
    });

    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    const [submitted, setSubmitted] = useState();

    const getUserData = (e) => {
        const { value, name } = e.target;
        let errors = errorObj;

        switch (name) {
            case 'name':
                errors.name =
                    value.length < 5
                        ? 'Full Name must be 5 characters long!'
                        : '';
                break;
            case 'email':
                errors.email =
                    validEmailRegex.test(value)
                        ? ''
                        : 'Email is not valid!';
                break;
            case 'phone':
                errors.phone =
                    value.length < 11
                        ? 'Phone number must be 11 characters long!'
                        : '';
                break;
            case 'Intention':
                errors.intention =
                    value.length < 3
                        ? 'Intention is compulsory'
                        : '';
                break;
            default:
                break;
        }
        setErrorObj(errors);
        setUser({ ...user, [name]: value })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        if (user.name && user.phone && user.email && user.intention && validateForm(errorObj)) {
            userService.createRequestForDemo(user).then(res => {
                setSubmitted(false);
                if (res.error) {
                    for (const error in res.data.errors) {
                        ToastsStore.error(res.data.errors[error][0])
                    }
                } else {
                    setUser({
                        name: '',
                        email: '',
                        phone: '',
                        intention: '',
                    })
                    ToastsStore.success('Your form has been submitted successfully. We will review it and get back to you.')
                    window.$('#demo').modal('hide');
                }
            }
            )
        } else {
            setSubmitted(false);
            ToastsStore.error('Check your form and try again')
        }
    }

    return (
        <div className="modal fade" id="demo" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="demo-page">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <img src="img/multiply.svg" alt="Close" />
                        </button>
                        <div className="d-flex justify-content-center mt-5 align-items-center flex-column demo-page__head">
                            <img src="img/demo-modal-img.svg" alt="Request for a demo" />
                            <p className="demo-page__head--main my-2">Request for a demo</p>
                            <p className="demo-page__head--sub">Kindly fill this form and we will get back to you as soon as possible</p>
                        </div>

                        <form className="my-4" onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="name">Name</label>
                                    <input type="text" className="form-control" name="name" value={user.name} onChange={getUserData} formNoValidate />
                                    {errorObj && errorObj.name &&
                                        <span className='error'>{errorObj.name}</span>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputPassword4">Phone Number</label>
                                    <input type="tel" className="form-control" name="phone" value={user.phone} onChange={getUserData} formNoValidate />
                                    {errorObj && errorObj.phone &&
                                        <span className='error'>{errorObj.phone}</span>}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="Email">Email</label>
                                <input type="email" className="form-control" name="email" value={user.email} onChange={getUserData} formNoValidate />
                                {errorObj && errorObj.email &&
                                        <span className='error'>{errorObj.email}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="intent">How you intend to use the platform</label>
                                <textarea className="form-control" name="intention" onChange={getUserData} value={user.intention} rows="3" formNoValidate></textarea>
                                {errorObj && errorObj.intention &&
                                        <span className='error'>{errorObj.intention}</span>}
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <button className="btn btn-danger button button--red" type="submit">{!submitted && 'Send your request'}{submitted && <i className="fa fa-spinner fa-spin"></i>}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageModal;
