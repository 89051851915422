import React, { useEffect, useState } from 'react';
import { userService } from '../services/http';
import MENV from "../env";
let api = MENV();

const ManFooter = (props) => {
    const [collection, setCollection] = useState();
    const [footerText, setFooterText] = useState();

    useEffect(() => {
        (async () => {
            const logo = await getLogo();
            setCollection(logo.data);

            const footerTextObj = await getFooterText();
            setFooterText(footerTextObj.data);
        })();
    }, [])

    const getLogo = async () => {
        const logo = await userService.getLogo();
        return logo;
    }

    const getFooterText = async () => {
        const text = await userService.getFooterText();
        return text;
    }
    return (
        <footer className="page-footer pt-5 pb-2">

            <div className="row m-0 my-5 align-items-center justify-content-center">
                <div className="col-md-9 row justify-content-center align-items-center">
                    {collection && collection.logo && <a href="index.html" className="navbar-brand d-flex justify-content-center align-items-center">
                        <img src={api.SERVICE_API + collection.logo.url} alt="" />
                    </a>}
                    <div className="col-md-2 my-3 d-flex flex-column text-left">
                        <a href="/#about">About us</a>
                        <a href="/">Blog</a>
                    </div>
                    <div className="col-md-2 my-3 d-flex flex-column text-left">
                        <a href="/privacy-policy">Privacy Policy</a>
                        <a href="/#about">Contact us</a>
                    </div>
                    <div className="col-md-3 my-3">
                        <button className="btn btn-danger button button--red" data-toggle="modal" data-target="#demo">Request a demo</button>
                    </div>
                </div>
                <div className="col-md-3 text-left">
                    <h5>Contact Us</h5>
                    {footerText && <p>{footerText.companyName}</p>}
                    {footerText && <p>
                        {footerText.address}
                    </p>}
                    {footerText && <p>
                        {footerText.contactNumber1}
                    </p>}
                    {footerText && <p>
                        {footerText.contactNumber2}
                    </p>}
                </div>
            </div>
            <hr className="footer-hr" />
            <p className="text-center footer-copyright">Copyright {new Date().getFullYear()} by <a href="/">I-Bitron Technologies Ltd</a></p>

        </footer>
    );
}

export default ManFooter;
