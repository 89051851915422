import React, { useEffect, useState } from 'react';
import { userService } from '../services/http';
import MENV from "../env";
let api = MENV();

const ManNavbar = (props) => {

    const [collection, setCollection] = useState();

    const [showSignupButton, setShowSignupButton] = useState();

    useEffect(() => {
        (async () => {

            const logo = await getLogo();
            const showSignupVar = await showSignup();

            setShowSignupButton(showSignupVar.data);
            setCollection(logo.data);


        })();
    }, [])

    const showSignup = async () => {
        const show = await userService.showSignup()
        return show;
    }

    const getLogo = async () => {
        const logo = await userService.getLogo();
        return logo;
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-light fixed-top navbar-main">
            {collection && collection.logo && <a id="js-brand-image-light" className={`navbar-brand d-flex js-scroll-trigger`} href="#header"><img src={api.SERVICE_API + collection.logo.url} alt="Page Logo" className="img-fluid" /></a>}
            <div className="mobile--menu__toggler" id="toggle">
                <span className="top"></span>
                <span className="middle"></span>
                <span className="bottom"></span>
            </div>

            <div className="nav-links">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a className="nav-link js-scroll-trigger" href="/#about">About</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link js-scroll-trigger" href="/#features">Features</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link js-scroll-trigger" href="/#work">How we work</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link js-scroll-trigger" href="/privacy-policy">Privacy Policy</a>
                    </li>
                    <li className="nav-item">
                        {showSignupButton && showSignupButton.show && <button className="btn btn-danger button button--nav" data-toggle="modal" data-target="#demoSignup">Sign Up</button>}
                        {showSignupButton && !showSignupButton.show && <button className="btn btn-danger button button--nav" data-toggle="modal" data-target="#demo">Request a demo</button>}
                    </li>
                </ul>
            </div>
            <div className="mobile--menu" id="mobile--menu">
                <div className="mobile--menu__content">
                    <div className="mobile--menu__toggler">
                        <span className="top"></span>
                        <span className="middle"></span>
                        <span className="bottom"></span>
                    </div>
                    <ul className="mobile--menu__links">
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger toggler" href="#about">About</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger toggler" href="#features">Features</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger toggler" href="#work">How we work</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link js-scroll-trigger toggler" href="/privacy-policy">Privacy Policy</a>
                        </li>
                        <li className="nav-item">
                            <button className="btn btn-danger button button--nav js-scroll-trigger toggler" data-toggle="modal" data-target="#demo">Request a demo</button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default ManNavbar;
