import React, { useEffect, useState } from 'react';
import ManFooter from '../../components/ManFooter';
import ManNavbar from '../../components/ManNavbar';
import { userService } from '../../services/http';
import Markdown from 'react-markdown'
import PageLoader from '../../components/PageLoader';



export const PrivacyPolicy = () => {

    const [collection, setCollection] = useState({});

    useEffect(() => {
        (async () => {
            const privacyText = await userService.getPrivacyText();
            setCollection(privacyText.data);
        })();
    }, [])

    return (
        <div className="App">
            <ManNavbar />
            <div className='policy-header'>
                <h1>Privacy Policy</h1>
            </div>
            <div className='container'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-md-8 my-5 text-left'>
                        {collection && collection.policy && (
                            <Markdown>
                                {collection.policy}
                            </Markdown>
                        )}
                        {!collection.policy && <PageLoader />}
                    </div>
                </div>
            </div>
            <ManFooter />
        </div>
    );
}
