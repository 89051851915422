import React, { useState } from 'react';
import ManFooter from '../../components/ManFooter';
import PageSteps from '../../components/PageSteps';
import PageFeatures from '../../components/PageFeatures';
import PageAbout from '../../components/PageAbout';
import ManNavbar from '../../components/ManNavbar';
import ManHeader from '../../components/ManHeader';
import PageModal from '../../components/PageModal';
import PageSignupModal from '../../components/PageSignupModal';
import PageLoader from '../../components/PageLoader';



export const Home = () => {

    return (
        <div className="App">
            <ManNavbar />
            <ManHeader />
            <PageAbout />
            <PageFeatures />
            <PageSteps />
            <ManFooter />
            <PageModal />
            <PageSignupModal />
        </div>
    );
}
