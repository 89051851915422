import React, { useEffect, useState } from 'react';
import { userService } from '../services/http';
import MENV from "../env";
let api = MENV();

const ManHeader = (props) => {

  const [collection, setCollection] = useState();
  const [showSignupButton, setShowSignupButton] = useState();

  useEffect(() => {
    (async () => {

      const mainText = await getMainTexts();
      const showSignupVar = await showSignup();

      setCollection(mainText.data);
      setShowSignupButton(showSignupVar.data);


    })();
  }, [])

  const getMainTexts = async () => {
    const mainText = await userService.getHeaderText();
    return mainText;
  }

  const showSignup = async () => {
    const show = await userService.showSignup()
    return show;
  }


  return (
    <div>
      {collection && <header id="header" className="header container">
        <div className="row mt-5">
          <div className="col-md-5 d-flex justify-content-center align-items-start flex-column">
            {collection.mainText && <h1 className="header__text header__text--main">
              {collection.mainText}
            </h1>}
            {collection.subText && <h2 className="header__text header__text--sub my-4">
              {collection.subText}
            </h2>}
            <div className="d-flex">
              {showSignupButton && showSignupButton.show && <button className="btn btn-danger button button--red wow mx-1 fadeInUp" data-wow-delay=".5s" data-toggle="modal" data-target="#demoSignup">Sign Up</button>}
              <button className="btn btn-danger button button--red--inverse wow fadeInUp mx-1" data-wow-delay=".5s" data-toggle="modal" data-target="#demo">Request a demo</button>
            </div>
            <div className="mt-5 d-flex flex-wrap">
              {collection.appLinkAndroid && <a className="wow fadeInUp mx-1 my-2" href={collection.appLinkAndroid}>
                <img alt="download svg" className="img-download img-fluid" src='/img/android.svg' />
              </a>}
              {collection.appLinkIos && <a className="wow fadeInUp mx-1 my-2" href={collection.appLinkIos}>
                <img alt="ios svg" className="img-download img-fluid" src='/img/ios.svg' />
              </a>}
            </div>
          </div>
          <div className="col-md-7 header-images justify-content-around align-items-center">
            <img alt="screenshot" className="img-header img-fluid wow fadeInRight" data-wow-delay=".5s" src={`${api.SERVICE_API}${collection.phoneImg1.url}`} />
            <img alt="screenshot" className="img-header img-fluid wow fadeInRight" data-wow-delay=".5s" src={`${api.SERVICE_API}${collection.phoneImg2.url}`} />
          </div>
        </div>
      </header>}
    </div>
  );

}

export default ManHeader;
