import React, { useState } from 'react';
import { userService } from '../services/http';
import { ToastsStore } from 'react-toasts';

const PageSignupModal = (props) => {
    const [user, setUser] = useState({
        estateName: '',
        estateAddress: '',
        estateContactPhone: '',
        estateContactPerson: '',
        estateOtherNumbers: '',
        estateEmail: '',
        chosenName: '',
        estateLogo: ''
    })

    const [errorObj, setErrorObj] = useState({
        estateName: '',
        estateAddress: '',
        estateContactPhone: '',
        estateContactPerson: '',
        estateOtherNumbers: '',
        estateEmail: '',
        chosenName: '',
    });

    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const validateForm = (errors) => {
        let valid = true;
        Object.values(errors).forEach(
            (val) => val.length > 0 && (valid = false)
        );
        return valid;
    }

    const [submitted, setSubmitted] = useState();
    const [image, setImage] = useState('');

    const getUserData = (e) => {
        const { value, name } = e.target;
        let errors = errorObj;

        switch (name) {
            case 'estateName':
                errors.estateName =
                    value.length < 3
                        ? 'Full Name must be 3 characters long!'
                        : '';
                break;
            case 'estateEmail':
                errors.estateEmail =
                    validEmailRegex.test(value)
                        ? ''
                        : 'Email is not valid!';
                break;
            case 'estateContactPhone':
                errors.estateContactPhone =
                    value.length < 11
                        ? 'Phone number must be 11 characters long!'
                        : '';
                break;
            case 'estateAddress':
                errors.estateAddress =
                    value.length < 3
                        ? 'This field is compulsory'
                        : '';
                break;
            case 'estateContactPerson':
                errors.estateContactPerson =
                    value.length < 3
                        ? 'This field is compulsory'
                        : '';
                break;
            case 'estateOtherNumbers':
                errors.estateOtherNumbers =
                    value.length < 3
                        ? 'This field is compulsory'
                        : '';
                break;
            case 'choosenName':
                errors.chosenName =
                    value.length < 3
                        ? 'This field is compulsory'
                        : '';
                break;
            default:
                break;
        }
        setErrorObj(errors);
        setUser({ ...user, [name]: value })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        if (user.estateName && user.estateAddress && user.estateContactPhone && user.estateContactPerson && user.estateOtherNumbers && user.estateEmail && user.chosenName && validateForm(errorObj)) {
            userService.createSignupForm(user).then(res => {
                setSubmitted(false);
                if (res.error) {
                    for (const error in res.data.errors) {
                        ToastsStore.error(res.data.errors[error][0])
                    }
                } else {
                    setUser({
                        estateName: '',
                        estateAddress: '',
                        estateContactPhone: '',
                        estateContactPerson: '',
                        estateOtherNumbers: '',
                        estateEmail: '',
                        chosenName: '',
                        estateLogo: ''
                    })
                    ToastsStore.success('Your form has been submitted successfully. We will review it and get back to you.')
                    window.$('#demoSignup').modal('hide');
                }
                return res;
            }
            ).then(async res => {
                const data = new FormData();
                data.append('files', image);
                data.append('refId', res.data.id);
                data.append('ref', 'signUpDetails');
                data.append('field', 'estateLogo');
                return await userService.upload(data);
            });
        } else {
            setSubmitted(false);
            ToastsStore.error('Check your form and try again')
        }
    }

    const getImage = (e) => {
        setImage(e.target)
    }

    return (
        <div className="modal fade" id="demoSignup" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="demo-page">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <img src="img/multiply.svg" alt="Close" />
                        </button>
                        <div className="d-flex justify-content-center mt-5 align-items-center flex-column demo-page__head">
                            <img src="img/comment.svg" alt="signup" />
                            <p className="demo-page__head--main my-2">Sign Up</p>
                            <p className="demo-page__head--sub">Kindly fill this form and we will get back to you as soon as possible</p>
                        </div>

                        <form className="my-4" onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="name">Estate Name</label>
                                    <input type="text" className="form-control" name="estateName" value={user.estateName} onChange={getUserData} formNoValidate />
                                    {errorObj && errorObj.estateName &&
                                        <span className='error'>{errorObj.estateName}</span>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="name"> Chosen Name (This will be used for **.mantis360.co)</label>
                                    <input type="text" className="form-control" name="chosenName" value={user.chosenName} onChange={getUserData} formNoValidate />
                                    {errorObj && errorObj.chosenName &&
                                        <span className='error'>{errorObj.chosenName}</span>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="name">Estate Contact Person</label>
                                    <input type="text" className="form-control" name="estateContactPerson" value={user.estateContactPerson} onChange={getUserData} formNoValidate />
                                    {errorObj && errorObj.estateContactPerson &&
                                        <span className='error'>{errorObj.estateContactPerson}</span>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputPassword4">Estate Contact Phone</label>
                                    <input type="tel" className="form-control" name="estateContactPhone" value={user.estateContactPhone} onChange={getUserData} formNoValidate />
                                    {errorObj && errorObj.estateContactPhone &&
                                        <span className='error'>{errorObj.estateContactPhone}</span>}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="inputPassword4">Estate Logo (optional)</label>
                                    <input type="file" className="form-control" name="estateLogo" onChange={getImage} formNoValidate />
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="Email">Estate Email (For account setup)</label>
                                    <input type="email" className="form-control" name="estateEmail" value={user.estateEmail} onChange={getUserData} formNoValidate />
                                    {errorObj && errorObj.estateEmail &&
                                        <span className='error'>{errorObj.estateEmail}</span>}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="Email">Other Estate Phone Numbers (separate by commas)</label>
                                <input type="tel" className="form-control" name="estateOtherNumbers" value={user.estateOtherNumbers} onChange={getUserData} formNoValidate />
                                {errorObj && errorObj.estateOtherNumbers &&
                                    <span className='error'>{errorObj.estateOtherNumbers}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="intent">Estate Address</label>
                                <textarea className="form-control" name="estateAddress" onChange={getUserData} value={user.estateAddress} rows="3" formNoValidate></textarea>
                                {errorObj && errorObj.estateAddress &&
                                    <span className='error'>{errorObj.estateAddress}</span>}
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <button className="btn btn-danger button button--red" type="submit">{!submitted && 'Send your request'}{submitted && <i className="fa fa-spinner fa-spin"></i>}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageSignupModal;
