const PROD = {
    SERVICE_API: 'https://admin.mantis360.co'
}

const DEV = {
    SERVICE_API: 'http://localhost:1337'
}

const env = 'production'

const MENV = () => {
    if(env === 'development') {
        return DEV
    }
    return PROD
}

export default MENV;