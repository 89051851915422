import React, { useState, useEffect } from 'react';
import { userService } from '../services/http';
import MENV from "../env";
let api = MENV();

const PageSteps = (props) => {
    const [collection, setCollection] = useState();

    useEffect(() => {
        (async () => {
            const stepsText = await getstepsTexts();
            const stepsHead = await getstepsHead();
            setCollection({ head: stepsHead.data, text: stepsText.data });
        })();
    }, [])

    const getstepsTexts = async () => {
        const stepsText = await userService.getStepsText();
        return stepsText;
    }

    const getstepsHead = async () => {
        const stepsHead = await userService.getStepsHead();
        return stepsHead;
    }
    return (
        <section id="work" className="page-section page-section__steps d-flex justify-content-center align-items-center flex-column">
            {collection && collection.head && <div className="page-section__head--inverted">
                <img src="img/inverted-bowl-white.svg" className="wow fadeInUp" alt="inverted bowl" />
                <h4 className="page-section__head--sub-white wow fadeInUp">{collection.head.sectionInfo}</h4>
                <h3 className="page-section__head--main-white font-weight-bold wow fadeInUp">{collection.head.sectionHead}</h3>
            </div>}
            <div className="row my-5">
                {collection && collection.text && collection.text.map((element, index) => {
                    return (<div key={index} className="col-lg-4 col-md-6 col-12">
                        <div className="d-flex justify-content-center align-items-center flex-column my-2">
                            <p className="page-section__steps-step-count">{index + 1} - step</p>
                            <img height="80" width="80" className="page-section__steps--image wow fadeIn" data-wow-delay=".2s" src={api.SERVICE_API + element.icon.url} alt="web" />
                            <p className="my-2 page-section__steps-main">{element.name}</p>
                            <p className="my-2 page-section__steps--sub">{element.description}</p>
                        </div>
                    </div>)
                })}
                <div className="col-12 d-flex justify-content-center align-items-center mt-5">
                    <button className="btn btn-danger button button--red" data-toggle="modal" data-target="#demo">Request a demo</button>
                </div>
            </div>

        </section>
    );
}

export default PageSteps;
