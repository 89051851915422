import React from 'react';
import './App.css';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { Home } from './pages/home';
import { PrivacyPolicy } from './pages/privacy-policy';

function App() {
  return (
    <>
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <Router>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path='/' element={<Home />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
