import React, { useState, useEffect } from 'react';
import MENV from "../env";
import { userService } from '../services/http';
let api = MENV();

const PageFeatures = (props) => {

    const [collection, setCollection] = useState();

    useEffect(() => {
        (async () => {
            const featureText = await getfeatureTexts();
            const featureHead = await getfeatureHead();
            setCollection({ head: featureHead.data, text: featureText.data });
        })();
    }, [])

    const getfeatureTexts = async () => {
        const featureText = await userService.getFeatureText();
        return featureText;
    }

    const getfeatureHead = async () => {
        const featureHead = await userService.getFeatureHead();
        return featureHead;
    }


    return (
        <div>
            {collection &&  collection.head && collection.head.show && <section id="features" className="page-section page-section__features d-flex justify-content-center align-items-center flex-column">
                {collection && collection.head && <div className="page-section__head">
                    <img src="img/inverted-bowl.svg" className="wow fadeInUp" alt="inverted bowl" />
                    <h4 className="page-section__head--sub wow fadeInUp">{collection.head.sectionInfo}</h4>
                    <h3 className="page-section__head--main font-weight-bold wow fadeInUp">{collection.head.sectionHead}</h3>
                </div>}
                <div className="row my-5">
                    {collection && collection.text && collection.text.map((element, index) => {
                        return (<div className="col-lg-4 col-md-6 col-12" key={index}>
                            <div className="d-flex justify-content-center align-items-center flex-column my-2 wow fadeIn" data-wow-delay=".2s">
                                <img height="80" width="80" className="page-section__features--image" src={api.SERVICE_API + element.icon.url} alt="Electricity" />
                                <p className="my-2 page-section__features-main">{element.name}</p>
                                <p className="my-2 page-section__features--sub">{element.description}</p>
                            </div>
                        </div>)
                    })}
                </div>

            </section>}
        </div>
    );
}

export default PageFeatures;
